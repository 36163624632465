import { CheckIcon } from '@bws-bitfy/icons-react';
import {
  Box,
  Stepper as MUIStepper,
  StepConnector,
  StepIconProps,
  SxProps,
  stepConnectorClasses,
  styled,
  useTheme
} from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Text from 'components/atoms/Text';
import { TranslationValuePath } from 'i18n';
import React, { useMemo } from 'react';

const Connector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.divider : '#eaeaf0',
    borderTopWidth: 1,
    borderRadius: 1
  }
}));

function Stepper({
  children,
  activeStep = 0,
  sxContent = {}
}: {
  children: JSX.Element | JSX.Element[] | React.ReactNode;
  activeStep?: number;
  sxContent?: SxProps;
}) {
  const theme = useTheme();
  const steps = useMemo(
    () =>
      Array.isArray(children)
        ? children?.map((child) => ({
            label: child.props.label,
            labelIntlPath: child.props.labelIntlPath,
            content: child
          }))
        : [
            {
              label: '',
              content: children
            }
          ],
    [children]
  );

  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, title } = props;

    return (
      <Box
        width={24}
        height={24}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={
          completed || active
            ? theme.palette.primaryOverlays[200]
            : `${theme.palette.primaryOverlays[200]}26`
        }
        borderRadius="50%"
      >
        {completed ? (
          <CheckIcon
            width={14}
            height={14}
            fill={theme.palette.primaryOverlays[900]}
          />
        ) : (
          <Text
            variant="body2"
            fontWeight={500}
            color={
              active || completed
                ? theme.palette.primaryOverlays[900]
                : 'text.secondary'
            }
          >
            {title}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <MUIStepper
        connector={<Connector />}
        activeStep={activeStep}
        sx={{
          backgroundColor: 'background.elevated',
          zIndex: 100,
          padding: { xs: '16px 32px', md: '20px 32px' },
          position: 'sticky',
          top: 0
        }}
      >
        {steps.map((step, index) => {
          return (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon {...props} title={index + 1} />
                )}
              >
                <Text
                  display={{ xs: 'none', md: 'inherit' }}
                  variant="body2"
                  fontWeight={500}
                  intlPath={step.labelIntlPath}
                >
                  {step.label}
                </Text>
              </StepLabel>
            </Step>
          );
        })}
      </MUIStepper>
      <Box
        sx={{
          overflowY: 'auto',
          paddingTop: { xs: 2, md: 4 },
          paddingBottom: { xs: 2, md: 4 },
          height: '100%',
          ...sxContent
        }}
      >
        {steps[activeStep]?.content}
      </Box>
    </Box>
  );
}

function StepContent({
  children,
  sxContent = {}
}: {
  children: JSX.Element;
  label?: string; // eslint-disable-line
  labelIntlPath?: TranslationValuePath; // eslint-disable-line
  sxContent?: SxProps;
}): JSX.Element {
  return (
    <Box
      paddingX={{ xs: 3, md: 4 }}
      sx={{ ...sxContent, height: '100% !important' }}
    >
      {children}
    </Box>
  );
}

Stepper.StepContent = StepContent;

export default Stepper;
