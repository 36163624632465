import {
  ArrowLeftIcon,
  CheckCircleDuotoneIcon,
  CheckIcon,
  CloseIcon
} from '@bws-bitfy/icons-react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
  SxProps,
  useTheme
} from '@mui/material';
import Button from 'components/atoms/Button';
import Condition from 'components/atoms/Condition';
import IconButton from 'components/atoms/IconButton/IconButton';
import If from 'components/atoms/If';
import Text from 'components/atoms/Text';
import { TextProps } from 'components/atoms/Text/Text';
import Stepper from 'components/molecules/Stepper';
import Tooltip from 'components/molecules/Tooltip';
import useDeviceSize from 'hooks/useDeviceSize';
import { TranslationValuePath } from 'i18n';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { successColor } from 'themes/dark-theme';

export const DIALOG_HEADER_ICON_SIZE = 36;
export interface DialogProps extends MUIDialogProps {
  'data-mixpanel'?: string;
  headerContent?: JSX.Element;
  title?: string;
  titleIntlPath?: TranslationValuePath;
  subtitle?: string;
  subtitleIntlPath?: TranslationValuePath;
  subtitleIcon?: JSX.Element;
  success?: boolean;
  titleFontWeight?: number;
  footerContent?: JSX.Element;
  sxContent?: SxProps;
  cancelButtonSX?: SxProps;
  styleContent?: React.CSSProperties;
  fullHeight?: boolean;
  descriptionText?: string;
  descriptionTextIntlPath?: TranslationValuePath;
  descriptionTextIntlProps?: Omit<TextProps, 'intlPath'>;
  titleIcon?: JSX.Element;
  loading?: boolean;
  hideDefaultButtons?: boolean;
  hideConfirmButtonIcon?: boolean;
  hideCloseIcon?: boolean;
  showOnlyConfirmButton?: boolean;
  disableConfirmButton?: boolean;
  disableCancelButton?: boolean;
  cancelButtonLabel?: string;
  cancelButtonLabelIntlPath?: TranslationValuePath;
  cancelButtonColor?: 'primary' | 'success' | 'error';
  confirmButtonLabel?: string;
  confirmButtonLabelIntlPath?: TranslationValuePath;
  confirmButtonLabelIntlProps?: Omit<TextProps, 'intlPath'>;
  confirmButtonVariant?: 'text' | 'overlayed' | 'contained';
  confirmButtonColor?: 'primary' | 'success' | 'error';
  confirmButtonType?: 'button' | 'reset' | 'submit';
  confirmButtonSX?: SxProps;
  formIdToSubmit?: string;
  onClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  customStartIcon?: JSX.Element;
  customConfirmButtonIcon?: JSX.Element;
  customCancelButtonIcon?: JSX.Element;
  hasStep?: boolean;
  activeStep?: number;
  disableFullScreen?: boolean;
  cancelButtonType?: 'button' | 'reset' | 'submit';
  isCancelButtonTriggeredFlag?: MutableRefObject<boolean>;
  titleStyle?: React.CSSProperties;
}

const Dialog: React.FC<DialogProps> = ({
  title,
  headerContent,
  footerContent,
  descriptionText,
  titleIcon,
  subtitle,
  subtitleIcon,
  success,
  titleFontWeight,
  onClose,
  onCancel,
  children,
  loading,
  onConfirm,
  cancelButtonLabel,
  confirmButtonLabel,
  confirmButtonVariant,
  confirmButtonType,
  confirmButtonColor,
  hideDefaultButtons,
  hideCloseIcon,
  hideConfirmButtonIcon,
  disableConfirmButton,
  showOnlyConfirmButton,
  fullHeight,
  formIdToSubmit,
  maxWidth,
  confirmButtonSX,
  customConfirmButtonIcon,
  customCancelButtonIcon,
  sxContent = {},
  styleContent = {},
  hasStep,
  activeStep = 0,
  disableFullScreen,
  cancelButtonSX,
  titleIntlPath,
  subtitleIntlPath,
  disableCancelButton,
  cancelButtonLabelIntlPath,
  confirmButtonLabelIntlPath,
  descriptionTextIntlPath,
  descriptionTextIntlProps,
  confirmButtonLabelIntlProps,
  cancelButtonType = 'button',
  isCancelButtonTriggeredFlag,
  cancelButtonColor,
  ...props
}) => {
  const theme = useTheme();
  const dialogContentRef = useRef<HTMLElement>(null);
  const [actionsBarBackground, setActionsBarBackground] = useState(false);
  const [width, height] = useDeviceSize();
  const [dialogHeight, setDialogHeight] = useState<number>();

  const confirmButtonLabelIntl = useMemo(() => {
    if (confirmButtonLabel) {
      return undefined;
    }

    return confirmButtonLabelIntlPath ?? 'common_translations.buttons.done';
  }, [confirmButtonLabel, confirmButtonLabelIntlPath]);

  const cancelButtonLabelIntl = useMemo(() => {
    if (cancelButtonLabel) {
      return undefined;
    }

    return cancelButtonLabelIntlPath ?? 'common_translations.buttons.cancel';
  }, [cancelButtonLabel, cancelButtonLabelIntlPath]);

  useEffect(() => {
    const token = setInterval(() => {
      if (dialogContentRef.current) {
        const heightClient = dialogContentRef.current.clientHeight;
        const heightScroll = dialogContentRef.current.scrollHeight;
        setDialogHeight(heightClient);
        if (heightScroll > heightClient) {
          setActionsBarBackground(true);
        }
        clearInterval(token);
      }
    }, 10);
    return () => clearInterval(token);
  }, [maxWidth, fullHeight, children, descriptionText, dialogContentRef]);

  useEffect(() => {
    setDialogHeight(dialogContentRef?.current?.clientHeight);
  }, [dialogContentRef?.current?.clientHeight]);

  return (
    <MUIDialog
      data-testid="dialog-component"
      {...props}
      scroll="paper"
      BackdropProps={{
        style: {
          backgroundColor: `${theme.palette.background.paper}DE`
        }
      }}
      PaperProps={{
        sx: {
          height: { xs: '100%', md: fullHeight ? '100%' : 'auto' },
          maxHeight: { md: 720 },
          maxWidth: { xs: '100%', sm: maxWidth === 'sm' ? 590 : 720 },
          borderRadius: { xs: 0, md: '24px' },
          backgroundImage: 'none'
        }
      }}
      fullWidth
      fullScreen
    >
      <DialogTitle
        sx={{
          backgroundColor: success
            ? successColor.overlay
            : 'background.elevated',
          color: success ? successColor.light : theme.palette.text.primary,
          paddingY: { xs: 0, md: 2.75 },
          paddingX: { xs: 0, md: 4 },
          paddingLeft: { xs: 1, md: 4 },
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Condition>
          <Condition.If condition={activeStep === 0}>
            <Box display={{ xs: 'inherit', md: 'none' }}>
              <Tooltip
                arrow
                placement="top"
                titleIntlPath="common_translations.buttons.close"
              >
                <IconButton
                  data-mixpanel={props?.['data-mixpanel']}
                  data-mixpanel-properties={{ close: 'true' }}
                  data-testid="dialog-close-button"
                  color="info"
                  size="small"
                  onClick={() => {
                    if (onCancel) {
                      onCancel();
                    } else {
                      onClose();
                    }
                  }}
                >
                  <CloseIcon fill={theme.palette.text.secondary} />
                </IconButton>
              </Tooltip>
            </Box>
          </Condition.If>
          <Condition.If condition={activeStep > 0}>
            <Box display={{ xs: 'inherit', md: 'none' }}>
              <IconButton
                data-testid="dialog-close-button"
                color="info"
                size="small"
                onClick={() => {
                  if (onCancel) {
                    onCancel();
                  } else {
                    onClose();
                  }
                }}
              >
                <ArrowLeftIcon fill={theme.palette.text.secondary} />
              </IconButton>
            </Box>
          </Condition.If>
        </Condition>
        <Box display="flex" alignItems="center" flex={1}>
          <Condition>
            <Condition.If condition={!!titleIcon}>
              <Box
                data-testid="dialog-title-icon"
                display={{ xs: 'none', md: 'flex' }}
                alignItems="center"
                style={{
                  fill: success
                    ? successColor.light
                    : theme.palette.primaryOverlays[200],
                  width: 36,
                  height: 36
                }}
              >
                {success ? (
                  <CheckCircleDuotoneIcon
                    height={DIALOG_HEADER_ICON_SIZE}
                    width={DIALOG_HEADER_ICON_SIZE}
                  />
                ) : (
                  titleIcon
                )}
              </Box>
            </Condition.If>
          </Condition>

          <Box marginX={titleIcon ? 2 : 0}>
            <Box
              sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              maxWidth={width - 190}
            >
              <Text
                data-testid="dialog-title"
                variant="h5"
                fontSize={{ xs: '1.25rem', md: '1.5rem' }}
                fontWeight={500}
                noWrap={width < theme.breakpoints.values.md}
                display={{
                  xs: activeStep === 0 ? 'inherit' : 'none',
                  md: 'inherit'
                }}
                intlPath={titleIntlPath}
              >
                {title}
              </Text>
            </Box>
            <Box
              sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              maxWidth={width - 250}
            >
              <Text
                data-testid="dialog-title"
                variant="h5"
                fontSize={{ xs: '1.25rem', md: '1.5rem' }}
                fontWeight={500}
                noWrap={width < theme.breakpoints.values.md}
                display={{
                  xs: activeStep === 0 ? 'none' : 'inherit',
                  md: 'none'
                }}
              >
                {Array.isArray(children)
                  ? children?.map((child, index) => {
                      if (index === activeStep && activeStep > 0) {
                        return child.props.label;
                      }
                      return '';
                    })
                  : ''}
              </Text>
            </Box>
            <Box display={{ xs: 'none', md: 'flex' }} alignItems="center">
              <Box
                data-testid="dialog-title-icon"
                display="flex"
                alignItems="center"
                color="success.light"
                sx={{
                  fill: 'currentcolor'
                }}
              >
                {subtitleIcon}
              </Box>
              <Text
                marginLeft={0.5}
                variant="body2"
                intlPath={subtitleIntlPath}
              >
                {subtitle}
              </Text>
            </Box>
          </Box>
        </Box>
        <If condition={!hideDefaultButtons}>
          <DialogActions
            data-testid="dialog-actions"
            sx={{
              display: { xs: 'inherit', md: 'none' }
            }}
          >
            <Button
              data-testid="dialog-confirm-button"
              data-mixpanel={props?.['data-mixpanel']}
              data-mixpanel-properties={{ cta: 'confirm' }}
              fullWidth
              variant={confirmButtonVariant || 'contained'}
              color={confirmButtonColor || 'primary'}
              disabled={disableConfirmButton || loading}
              onClick={onConfirm}
              sx={confirmButtonSX}
              type={confirmButtonType || 'button'}
              form={formIdToSubmit}
              startIcon={
                hideConfirmButtonIcon ??
                (customConfirmButtonIcon || <CheckIcon />)
              }
              intlpath={confirmButtonLabelIntl}
            >
              {confirmButtonLabel}
            </Button>
          </DialogActions>
        </If>
        <Condition>
          <Condition.If condition={!hideCloseIcon}>
            <Box display={{ xs: 'none', md: 'inherit' }}>
              <Tooltip
                arrow
                placement="right"
                titleIntlPath="common_translations.buttons.close"
              >
                <IconButton
                  data-testid="dialog-close-button"
                  data-mixpanel={props?.['data-mixpanel']}
                  data-mixpanel-properties={{ close: 'true' }}
                  color="info"
                  size="small"
                  onClick={onClose}
                >
                  <CloseIcon fill={theme.palette.text.secondary} />
                </IconButton>
              </Tooltip>
            </Box>
          </Condition.If>
        </Condition>
      </DialogTitle>
      {headerContent}
      <DialogContent
        style={{
          // paddingTop: theme.spacing(4),
          ...styleContent
        }}
        sx={{
          padding: { xs: hasStep ? 0 : 3, md: hasStep ? 0 : 4 },
          backgroundColor: 'background.default',
          ...sxContent,
          overflow: hasStep ? 'hidden' : 'auto'
        }}
        ref={dialogContentRef}
      >
        <Condition>
          <Condition.If condition={loading}>
            <Box
              position="fixed"
              top={0}
              right={0}
              left={0}
              color={theme.palette.primaryOverlays[200]}
            >
              <LinearProgress data-testid="dialog-loading" color="inherit" />
            </Box>
          </Condition.If>
        </Condition>

        <Condition>
          <Condition.If condition={!children}>
            <Box pt={3}>
              <Text
                data-testid="dialog-description-text-content"
                variant="body1"
                color="textPrimary"
                intlPath={descriptionTextIntlPath}
              >
                {descriptionText}
              </Text>
            </Box>
          </Condition.If>
          <Condition.Else>
            <>
              <If condition={!hasStep}>
                <Box
                  data-testid="dialog-children-content"
                  pt={{ xs: 2, md: 4 }}
                  height="100%"
                >
                  {children}
                </Box>
              </If>
              <If condition={hasStep}>
                <Stepper
                  activeStep={activeStep}
                  sxContent={{
                    maxHeight:
                      width < theme.breakpoints.values.md
                        ? `calc(${height}px - 53px - 56px)`
                        : `calc(${dialogHeight}px - 64px)`,
                    height: '100%'
                  }}
                >
                  {children}
                </Stepper>
              </If>
            </>
          </Condition.Else>
        </Condition>
      </DialogContent>
      <Box>{footerContent}</Box>
      <Condition>
        <Condition.If condition={!hideDefaultButtons}>
          <DialogActions
            data-testid="dialog-actions"
            sx={{
              paddingY: '20px',
              paddingX: 4,
              backgroundColor: actionsBarBackground
                ? 'background.elevated'
                : 'background.default',
              display: { xs: 'none', md: 'inherit' }
            }}
          >
            <Grid container spacing={2}>
              {!showOnlyConfirmButton && (
                <Grid item xs={12} sm={6}>
                  <Button
                    data-testid="dialog-cancel-button"
                    data-mixpanel={props?.['data-mixpanel']}
                    data-mixpanel-properties={{ cta: 'cancel' }}
                    fullWidth
                    disabled={disableCancelButton}
                    color={cancelButtonColor ?? 'primary'}
                    variant="overlayed"
                    sx={cancelButtonSX}
                    onClick={() => {
                      if (cancelButtonType === 'submit') {
                        isCancelButtonTriggeredFlag.current = true;

                        const evt = new Event('submit', {
                          bubbles: true,
                          cancelable: false
                        });
                        document
                          .getElementById(formIdToSubmit)
                          ?.dispatchEvent(evt);
                      } else if (onCancel) {
                        onCancel();
                      } else {
                        onClose();
                      }
                    }}
                    startIcon={customCancelButtonIcon}
                    intlpath={cancelButtonLabelIntl}
                  >
                    {cancelButtonLabel}
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} sm={showOnlyConfirmButton ? 12 : 6}>
                <Button
                  data-testid="dialog-confirm-button"
                  data-mixpanel={props?.['data-mixpanel']}
                  data-mixpanel-properties={{ cta: 'confirm' }}
                  fullWidth
                  variant={confirmButtonVariant || 'contained'}
                  color={confirmButtonColor || 'primary'}
                  disabled={disableConfirmButton || loading}
                  onClick={onConfirm}
                  sx={confirmButtonSX}
                  type={confirmButtonType || 'button'}
                  form={formIdToSubmit}
                  startIcon={
                    hideConfirmButtonIcon ??
                    (customConfirmButtonIcon || <CheckIcon />)
                  }
                  intlpathprops={confirmButtonLabelIntlProps}
                  intlpath={confirmButtonLabelIntl}
                >
                  {confirmButtonLabel}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Condition.If>
      </Condition>
    </MUIDialog>
  );
};

export default Dialog;
