import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps
} from '@mui/material';
import Tooltip from 'components/molecules/Tooltip';
import { TranslationValuePath } from 'i18n';
import { SnackbarMessage, useSnackbar } from 'notistack';
import Condition from '../Condition';

export interface IconButtonProps extends MuiIconButtonProps {
  clipboardText?: string;
  clipboardSuccessMessage?: string;
  clipboardSuccessMessageIntlPath?: TranslationValuePath;
  tooltipText?: string;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  'data-mixpanel'?: string;
  'data-mixpanel-properties'?: { [key: string]: string };
}

function IconButton({
  tooltipText,
  tooltipPlacement,
  ...props
}: IconButtonProps) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Condition>
      <Condition.If condition={!!tooltipText}>
        <Tooltip placement={tooltipPlacement ?? 'top'} title={tooltipText}>
          <MuiIconButton
            {...props}
            onClick={
              props?.clipboardText
                ? () => {
                    navigator.clipboard
                      .writeText(props.clipboardText)
                      .then(() => {
                        enqueueSnackbar({
                          type: 'success',
                          title: props.clipboardSuccessMessage,
                          titleIntlPath: props.clipboardSuccessMessageIntlPath
                        } as unknown as SnackbarMessage);
                      });
                  }
                : (event) => {
                    if (props?.onClick) {
                      props.onClick(event);
                    }
                  }
            }
          />
        </Tooltip>
      </Condition.If>
      <Condition.Else>
        <MuiIconButton
          {...props}
          onClick={
            props?.clipboardText
              ? () => {
                  navigator.clipboard
                    .writeText(props.clipboardText)
                    .then(() => {
                      enqueueSnackbar({
                        type: 'success',
                        title: props.clipboardSuccessMessage,
                        titleIntlPath: props.clipboardSuccessMessageIntlPath
                      } as unknown as SnackbarMessage);
                    });
                }
              : (event) => {
                  if (props?.onClick) {
                    props.onClick(event);
                  }
                }
          }
        />
      </Condition.Else>
    </Condition>
  );
}

export default IconButton;
